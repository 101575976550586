import React from 'react';
import StaticLayout from '~layouts/static-layout';
import { RenderedContext } from '~layouts/layout';
import Header from '~2-components/Header/Header';
import { ProfileWithImageBannerData } from '~data/profileWithImageBannerData';
import PageSection from '~2-components/PageSection/PageSection';
import PracticeAreasList from '~2-components/PracticeAreasList/PracticeAreasList';
import VideoPanel from '~2-components/VideoPanel/VideoPanel';
import TestimonialItem from '~2-components/TestimonialItem/TestimonialItem';
import TestimonialCarousel from '~2-components/TestimonialCarousel/TestimonialCarousel';
import { dummyTestimonials } from '~data/dummyTestimonials';

const ExampleHomePage = () => (
    <StaticLayout>
        <RenderedContext.Provider value={ProfileWithImageBannerData}>
            <Header />
        </RenderedContext.Provider>

        <div className="page-content">
            <div className="container">
                <PageSection
                    textLayout="two_columns"
                    rightColContent={<PracticeAreasList practiceAreas={ProfileWithImageBannerData.practiceAreas} />}
                >
                    <h4>Bree Knoester is the Managing Partner of Adviceline Injury Lawyers and leads the firm’s occupational diseases litigation and sexual abuse practices.</h4>

                    <p>An expert in industrial injuries and occupational diseases (asbestos, silicosis, dust), Bree also has significant experience in medical negligence, sexual abuse and catastrophic injury compensation.</p>

                    <p>She is dedicated to ensuring that her clients are properly compensated for their injuries and thrives on providing advice and support to her clients throughout their whole case.</p>

                    <p>Bree understands that for clients, going to court can be an overwhelming and stressful experience. She is committed to ensuring that her clients understand what is happening with their case every step of the way.</p>

                    <p>Bree regularly provides media organisations with expert commentary on a range of personal injury matters; particularly medical negligence, abuse and industrial accidents and diseases.</p>

                    <p>For a term of three years, Bree has been appointed to the Presumptive Rights Advisory Committee. In this role she will assist in the determination of claims brought by Emergency Services workers under the new Firefighters’ Presumptive Rights and Fire Services Amendment Legislation (Reform) Bill 2019.</p>
                </PageSection>
                <PageSection
                    backgroundTheme="grey"
                >
                    <VideoPanel
                        videoSrc='YouTube'
                        videoId='6LTPM7ZI4r0'
                    />
                </PageSection>
                <PageSection
                    backgroundTheme="grey"
                    textLayout="two_columns"
                    rightColContent={<TestimonialItem
                        content="What a pleasure it’s been to work with Shyla, Nicole and Lauren. I just want to thank you for your commitment, honesty and graciousness. You guys have helped me close a door, move forward AND with my dignity in place. Thank you so much."
                        name="Nataly Ceballos"
                    />}
                >
                    <h3>Experience</h3>
                    <p>Over the course of her career, Bree has been involved in many of the largest cases and settlements in personal injury litigation.</p>
                    <ul>
                        <li><a href="https://advicelineinjurylawyers.com.au/2019/02/22/reporter-ptsd/"><strong>YZ v The Age</strong></a><strong>:</strong>&nbsp;After eight years and two landmark litigation law suits, an Australian Court recently determined that any journalist whose role requires coverage of traumatic events is at risk of developing a psychological injury. Bree led both claims, achieving this world-first judgment against a media organisation.&nbsp;The ruling followed a three week civil trial in late 2018, where an injured crime reporter was awarded $180,000 for psychological injury suffered during the decade she worked at a Melbourne-based newspaper.</li>
                        <li><a href="https://advicelineinjurylawyers.com.au/2017/08/15/settlement-churchyard-estate/"><strong>Medical abuse</strong></a><strong>:&nbsp;</strong>Bree has negotiated settlements for many survivors of sexual abuse, representing both children and adults in both civil and institutions claims. In Australia’s largest medical abuse case, Bree negotiated a settlement on behalf of 60 claimants against former neurologist Dr Andrew Churchyard in 2017. This case paved the way for changing the standards of patient safety across Australia, and pressured the Australian Health Practitioner Regulation Agency (AHPRA) and the Medical Board of Australia (MBA) to conduct an independent review into whether chaperone restrictions are effective whilst allegations of sexual misconduct are investigated.</li>
                    </ul>
                    <p>Prior to joining the firm, Bree was at the Victorian Bar for eight years. As a barrister, Bree regularly appeared in the County Court, Supreme Court and Court of Appeal.</p>
                    <p>Bree’s experience as a barrister means her clients know she is an expert at navigating the court system. Her advocacy skills are an important part of her skill-set, enabling her to successfully advocate for her clients and negotiate excellent outcomes.</p>
                    <h3>Qualifications</h3>
                    <ul>
                        <li>Bachelor of Laws (Honours) – Monash University</li>
                        <li>Bachelor of Arts – Monash University</li>
                        <li>Masters of Laws – Monash University</li>
                    </ul>
                    <h3>Awards</h3>
                    <ul>
                        <li>Best Lawyers® in Australia:&nbsp;Lawyer of the Year in Personal Injury Litigation (2020)</li>
                        <li>Best Lawyers® in Australia:&nbsp;Personal Injury Litigation (2019-2021)</li>
                        <li>Best Lawyers® in Australia:&nbsp;Medical Negligence (2018-2021)</li>
                        <li>Doyle’s Guide:&nbsp;Leading practitioner – Asbestos &amp; Dust Diseases Compensation (2016 &amp; 2018 – 2019)</li>
                        <li>Doyle’s Guide:&nbsp;Leading practitioner – Work Injury Compensation (2017 – 2019)</li>
                        <li>Doyle’s Guide:&nbsp;Leading practitioner – Medical Negligence Compensation (2019)</li>
                        <li>Doyle’s Guide:&nbsp;Recommended practitioner – Public Liability Compensation (2019)</li>
                    </ul>
                    <h3>Memberships</h3>
                    <ul>
                        <li>Australian Lawyers Alliance</li>
                        <li>Editorial Committee – Precedent magazine</li>
                        <li>Presumptive Rights Advisory Committee (2019 – 2022)</li>
                        <li>Board of Directors – Dart Centre for Journalism and Trauma (Asia Pacific)&nbsp;(2014 – 2016)</li>
                        <li>Member of Gippsland Asbestos Related Diseases Support Inc (GARDS)/Asbestos Council of Victoria</li>
                    </ul>
                </PageSection>
                <TestimonialCarousel
                    testimonials={dummyTestimonials}
                />
            </div>
        </div>
    </StaticLayout>
);

export default ExampleHomePage;
