import formatPhoneNumber from '~utils/formatPhoneNumber';

export const ProfileWithImageBannerData = {
    fullName: `Bree Knoester`,
    position: `Managing Partner`,
    location: [
        {
            name: `Melbourne`,
            address: `Level 8, 555 Bourke Street, Melbourne 3000`
        },
        {
            name: `Morwell`,
            address: `10 George Street`
        }
    ],
    phone: {
        txt: `(03) 9321 9879`,
        url: `tel:${formatPhoneNumber('(03) 9321 9879)')}`
    },
    email: {
        txt: `Email`,
        url: `mailto:bree.knoester@alil.com.au`
    },
    hasImage: true,
    heroImage: {
        mobileImage: 'https://source.unsplash.com/featured/767x664?people',
        tabletImage: 'https://source.unsplash.com/featured/1023x800?people',
        desktopImage: 'https://source.unsplash.com/featured/1600x800?people',
    },
    breadcrumbs: [
        {
            title: 'Home',
            isActive: true
        }
    ],
    aboutText: ``,
    practiceAreas: [
        {
            title: 'Asbestos Law',
            url: '#'
        },
        {
            title: 'Sexual Abuse Compensation',
            url: '#'
        },
        {
            title: 'Workers Compensation',
            url: '#'
        },
        {
            title: 'Coronial Investigations and Inquests',
            url: '#'
        }
    ]
};
